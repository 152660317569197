








































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      id: 1,
      formSchemaSource: {},
      form: {},
      home: {},
      data: {},
      showDrawerOutside: false,
    };
  },
  created() {
    if (
      this.$route.params.tableName === "services" &&
      this.$route.params.id === "1"
    ) {
      this.$router.replace({ path: "/xiuduyou" });
    }
    import(`@/common/form/${this.$route.params.tableName}`).then((module) => {
      this.formSchemaSource = module.default;
      this.showDrawerOutside = true;
    });
  },
  mounted() {
    console.log(this.$route);
  },

  methods: {
    goService(id: number) {
      console.log(id);
    },
  },
});
